<template>
  <div class="page" ref="page">
    <navTop
      title="2023年武夷山市“一把手”招商攻坚项目动态管理手册"
      :leftIcon="true"
    ></navTop>
    <div v-if="!showPass"  class="manage">
      <div class="home-title">
        <div class="home-sub" @click="showStatus">

          {{ statusTabText }}
          <van-icon name="arrow-down" color="#fff" v-if="!statusTab" />
          <van-icon name="arrow-up" color="#fff" v-if="statusTab" />
         
        </div>
        <div class="home-sub" @click="showTab">
          {{categoryTabText}}
          <van-icon name="arrow-down" color="#fff" v-if="!show" />
          <van-icon name="arrow-up" color="#fff" v-if="show" />
       
        </div>
        <div class="mask" v-if="statusTab" @click.stop>
            <div class="home-sub-box">
              <div class="home-tab">
                <div
                  class="home-tab-item"
                  :class="statusCurrent == item.id ? 'home-item-select' : ''"
                  v-for="(item, index) in statusList"
                  :key="index"
                  @click.stop="setStatusCurrent(item.id)"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="home-sub-btn">
                <div class="cancel" @click.stop="showStatus">取消</div>
                <div class="sure" @click.stop="sureStatusBtn">确定</div>
              </div>
            </div>
        </div>

        <div class="mask" v-if="show" @click.stop>
          <div class="home-sub-box">
            <div class="home-tab">
              <div
                class="home-tab-item1"
                v-for="(item, index) in tabList"
                :key="index"
                @click.stop="setCurrent(item.id)"
              >
                <van-badge color="none"  class="my-badge">
                  <div :class="current == item.id ? 'badge-child-select': 'badge-child'"> {{ item.name }}</div>
                  <template #content v-if="item.flag == 'hot'">
                    <van-icon name="fire" color="#ee0a24" class="badge-icon" />
                  </template>
                </van-badge>
              </div>
            </div>
            <div class="home-sub-btn">
              <div class="cancel" @click.stop="showTab">取消</div>
              <div class="sure" @click.stop="sureBtn">确定</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 底下列表 -->
      <div class="manage-list-box">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <div>
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="companyPageList"
             
            >
              <div  class="manage-list">
                <div
                  class="manage-list-item"
                  v-for="(item, index) in list"
                  :key="index"
                  @click="goDetails(item.id)"
                >
                  <van-icon v-if="item.category.flag == 'hot'" name="fire" color="#ee0a24" class="fire-icon" />
                  <div class="list-item-min">
                    <div class="list-item-title">{{ item.category_name }}</div>
                    <div class="list-item-sub">
                      {{ item.name }}
                    </div>
                    <van-tag :color="statusColor[item.status - 1]">{{ item.status_text }}</van-tag>
                    <div class="list-item-num-box">
                      <div class="list-item-num">
                        项目投资{{ item.input_money }}
                      </div>
                      <div class="list-item-num">
                        挂点领导：{{ item.leader }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
          </div>
        </van-pull-refresh>
      </div>
    </div>
    <VerifyPass @pass-true="showPass = !showPass"></VerifyPass>
  </div>
</template>

<script>
import { projectPageList, getProjectCategory } from "@/api/index";
import navTop from "@/components/common/navTop.vue";
import VerifyPass from "@/components/common/verifyPass.vue";
export default {
  components: {
    navTop, VerifyPass
  },
  data() {
    return {
      page: 1,
      pages: 1,
      showPass: true,
      type: 0,
      current: 0,
      tabList: [],
      show: false,
      list: [],
      statusTab: false,
      statusTabText: '项目状态',
      statusCurrent: 0,
      loading: false,
      finished: false,
      refreshing: false,
      categoryTabText: "项目类型",
      statusColor: ['#0000ff', '#ebbf00', '#ffa500', '#008000'],
      statusList: [
        {id: 0, name: '全部'},
        {id: 1, name: '在谈项目'},
        {id: 2, name: '已签约项目'},
        {id: 3, name: '落地后停滞项目'},
        {id: 4, name: '已落地项目'},
      ]
    };
  },
  watch:{
    showPass(newVal, oldVal) {
      if(!newVal) {
        this.getSubCategory();
        this.initCompanyList();
        this.handleScrollTop();
      }
    }
  },
  created() {
    this.type = this.$route.query.type;
  },
  methods: {
    // 滚动到顶部
    handleScrollTop() {
      this.$nextTick(() => {
        let scrollElem = this.$refs.page;
        scrollElem.scrollTo({ top: 0, behavior: "smooth" });
      });
    },
    // 项目列表
    async companyPageList() {
      if (this.page > this.pages) {
        this.finished = true;
        return false;
      }
      let params = {
        page: this.page,
        type: this.type,
        status: this.statusCurrent,
        category_id: this.current,
      };
      this.loading = true;
      try {
        const { code, data } = await projectPageList(params);
        let list = this.list.concat(data.data);
        this.list = list;
        this.page++;
        this.pages = data.last_page;
        this.loading = false;
        this.refreshing = false;
      } catch (e) {
        console.log(e);
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.initCompanyList();
    },
    goDetails(id) {
      this.$router.push({
        path: "/details",
        query: {
          id: id,
        },
      });
    },
    async getSubCategory() {
      try {
        const { code, data } = await getProjectCategory();
        this.tabList = [{ id: 0, name: "全部" }].concat(data);
      } catch (e) {
        console.log(e);
      }
    },
    sureBtn() {
      this.onRefresh();
      this.tabList.forEach(element => {
        if(element.id === this.current) {
          this.categoryTabText = element.name
        }
      });
      this.showTab();
    },
    sureStatusBtn() {
      this.onRefresh();
      this.statusList.forEach(element => {
        if(element.id === this.statusCurrent) {
          this.statusTabText = element.name
        }
      });
      this.showStatus();
    },
    // 初始化列表
    initCompanyList() {
      this.page = 1;
      this.pages = 1;
      this.list = [];
      this.companyPageList();
    },
    // 项目类型
    showTab() {
      this.show = !this.show;
      if(this.statusTab) {this.showStatus()} // 避免与状态同时展开
    },
    // 项目状态
    showStatus(){
      this.statusTab = !this.statusTab;
      if(this.show) {this.showTab()}
    },
    setCurrent(e) {
      this.current = e;
    },
    setStatusCurrent(e) {
      this.statusCurrent = e;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.page {
  height: 100vh;
}
.manage {
  padding-top: 20px;
}
.home-title {
  color: #fff;
  font-size: 16px;
  background-color: #556efe;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.home-sub {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;
  position: relative;
  height: 50px;
  line-height: 50px;
  padding-right: 20px;
}
.business-next-down {
  width: 13px;
  height: 10px;
}
.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  z-index: 10;
  height: 100vh;
}
.home-sub-box {
  padding: 15px;
  background-color: #fff;
}
.home-sub-btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 25px;
  margin-top: 10px;
}
.cancel {
  flex: 1;
  color: #666;
  text-align: center;
}
.sure {
  flex: 1;
  text-align: center;
  color: #556efe;
}
.home-tab {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.home-tab-item {
  background-color: #f3f3f3;
  border-radius: 15px;
  padding: 5px 10px;
  line-height: 22px;
  font-size: 14px;
  min-width: 75px;
  margin-bottom: 10px;
  margin-right: 10px;
  color: #666;
  text-align: center;
}
.home-tab-item1 {
  line-height: 22px;
  font-size: 14px;
  min-width: 75px;
  margin-bottom: 10px;
  color: #666;
  text-align: center;
}
.home-tab .home-item-select {
  border: 1px solid #eee;
  color: #556efe;
  background-color: #fff;
}

.manage-list-box {
  background-color: #bdd7fc;
  min-height: 100vh;
}
/* 底下列表的样式 */
.manage-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-bottom: 15px;
}
.manage-list-item {
  width: 35%;
  margin-left: 3%;
  text-align: center;
  padding: 15px 20px 15px 15px;
  color: #333;
  font-size: 13px;
  margin-bottom: 10px;
  background-image: url("../assets/imgs/manage_book_bg.png");
  background-size: 100% 100%;
  position: relative;
}
// .list-item-min {
// }
.list-item-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.list-item-title {
  color: #e94b00;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 14px;
}
.list-item-sub {
  line-height: 20px;
  font-size: 16px;
  color: #000;
  max-height: 80px;
  min-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  margin-bottom: 10px;
  font-weight: 700;
}
.list-item-num {
  text-align: left;
  color: #333;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  margin-top: 4px;
  margin-left: 16px;
}
.list-item-num-box{
  margin-top: 6px;
}
.badge-icon {
  display: block;
  font-size: 15px;
  line-height: 16px;
}
.badge-child{
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 15px;
  background-color: #f3f3f3;
  border: 1px solid #fff;
}
.badge-child-select{
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 15px;
  border: 1px solid #eee;
  color: #556efe;
  background-color: #fff;
}
.home-tab-item .van-badge {
  right: -14px;
}
.my-badge .badge-icon {
  right: 15px;
  top: 5px;
}
.fire-icon{
  position: absolute;
  right: 15px;
  top: 5px;
}
</style>
